<template>
  <div class="app-container">
    <CrudTable entity="ScratchAndWinAction" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { toDateStringVisual } from '@/utils/date';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'name' },
        { field: 'startDate', label: 'scratchAndWin.startDate', filter: { type: 'none' }, formatter: this.formatDate },
        { field: 'endDate', label: 'scratchAndWin.endDate', filter: { type: 'none' }, formatter: this.formatDate }
      ]
    };
  },
  methods: {
    formatDate(row, column, cellValue) {
      return toDateStringVisual(cellValue);
    }
  }
};
</script>

<style scoped></style>
